<template>
  <section class="hero is-fullheight" data-anchor="video" id="video">
    <div class="my-col-width">
      <h2 class="my-sectionHeader">Видео</h2>
    </div>
    <div class="my-col-fullwidth">
      <div class="my-carousel-wrapper">
        <div class="my-side-header-block">
          <h3 class="my-side-header-block_text">Видео</h3>
        </div>
        <b-carousel-list
          v-model="activeSliderIndex"
          :data="items"
          :arrow="false"
          :arrow-hover="false"
          :items-to-show="1"
          :items-to-list="1"
          :repeat="false"
          :has-drag="true"
        >
          <template #item="list">
            <div class="my-carousel-img-wapper" @click="getImageSrc(list.index)">
              <img class="my-carousel-img" :src="list.imageSrc" />
              <caption class="my-carousel-img_caption">
                {{
                  list.title
                }}
              </caption>
              <button class="my-carousel-img_modal-link"></button>
            </div>
          </template>
        </b-carousel-list>
      </div>
    </div>
    <b-modal 
    v-model="isImageModalActive"
    @close="closeImageModal" 
    class="my-iframe-modal">
      <figure class="image is-16by9 my-w100">
        <b-loading v-model="isiframeLoading" :can-cancel="true"></b-loading>
        <iframe
          class="has-ratio"
          width="560"
          height="315"
          src="https://www.youtube.com/embed/W7wA1ALndDY"
          frameborder="0"
          allowfullscreen
          @load="isiframeLoading = false"
        ></iframe>
      </figure>
    </b-modal>
  </section>
</template>

<script>
export default {
  name: "videoSection",
  data() {
    return {
      isiframeLoading: true,
      isImageModalActive: false, //флаг открытия модалки
      activeSliderIndex: 0, //пока не актуальны по тому что в слайдер показывает только одну фотографию
      inModalImageIndex: 0, //тоже
      items: [
        {
          title: "Смелое архитектурное решение",
          imageSrc: "img/video-bg.jpg",
          videoSrc: "https://www.youtube.com/embed/jAz66kq0X9c",
        },
      ],
    };
  },
  methods: {
    getImageSrc: function (index) {
      this.inModalImageIndex = index;
      this.isImageModalActive = true;
      this.$root.$options.pagesLib.scrolling = true;
    },
    closeImageModal: function() {
      this.$root.$options.pagesLib.scrolling = false;
    },
    incrementImageIndex(sliderName) {
      if (sliderName == "activeSlider" && this.activeSliderIndex < this.items.length) {
        this.activeSliderIndex++;
      } else if (sliderName == "modalSlider" && this.inModalImageIndex < this.items.length - 1) this.inModalImageIndex++;
    },
    decrementImageIndex(sliderName) {
      if (sliderName == "activeSlider" && this.activeSliderIndex > 0) {
        this.activeSliderIndex--;
      } else if (sliderName == "modalSlider" && this.inModalImageIndex > 0) this.inModalImageIndex--;
    },
  },
};
</script>

<style scoped>
.my-iframe-modal >>> .modal-content {
  display: flex;
  justify-content: center;
}

.my-carousel-wrapper {
  --slider-height: calc(100vh - 250px);
  position: relative;
  display: flex;
}

.my-carousel-img {
  height: var(--slider-height);
  object-fit: cover;
  transition: transform 8s linear;
  width: 100%;
}

.my-carousel-arrow {
  position: absolute;
  color: #fff;
  font-size: 1.5rem;
  line-height: 1.5rem;
  letter-spacing: 0.1rem;
  cursor: default;
  opacity: 0;
  transition: opacity 0.2s;
}

.my-carousel-arrow.active {
  cursor: pointer;
  opacity: 1;
}

.my-carousel-arrow::selection {
  background-color: transparent;
}

.my-carousel-arrow_forvard {
  top: 50%;
  right: 5rem;
}

.my-carousel-arrow::after {
  content: "";
  background-position: center;
  background-repeat: no-repeat;
  background-color: transparent;
  background-size: contain;
  display: inline-block;
  width: 2rem;
  height: 1.5rem;
  margin: 0.25rem 1rem;
}

.my-carousel-arrow_forvard::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='100%' height='100%' viewBox='0 0 82 66'%3E%3Cpath d='M4 37h62L45 58a4 4 0 1 0 6 6l31-31L51 2a4 4 0 0 0-6 6l21 21H4a4 4 0 1 0 0 8Z'/%3E%3C/svg%3E");
  float: right;
}

.my-carousel-arrow_back::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='100%' height='100%' viewBox='0 0 82 66'%3E%3Cpath d='M78 29H16L37 8a4 4 0 1 0-6-6L0 33l31 31a4 4 0 1 0 6-6L16 37h62a4 4 0 1 0 0-8Z'/%3E%3C/svg%3E%0A");
  float: left;
}

.my-carousel-arrow_back {
  top: 50%;
  left: 15rem;
}

.my-carousel-img-wapper {
  position: relative;
  height: var(--slider-height);
  overflow: hidden;
}

/*затемнение для not hover картинок*/
.my-carousel-img-wapper::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #111;
  opacity: 0.7;
  transition: opacity 0.5s;
}
/*снимаем затемнение при hover*/
.my-carousel-img-wapper:hover::after {
  opacity: 0;
  transition: opacity 0.5s;
}
/*зум эффект при hover*/
.my-carousel-img-wapper:hover .my-carousel-img {
  transform: scale(1.4);
}

.my-carousel-img-wapper:hover .my-carousel-img_modal-link {
  opacity: 1;
  transform: scale(1);
}

/**/
.my-carousel-img_caption {
  position: absolute;
  top: 3rem;
  left: 3rem;
  font-weight: 400;
  font-size: 1.56rem;
  line-height: 1.45;
  letter-spacing: 0.07rem;
  color: #fff;
  text-transform: uppercase;
  z-index: 1;
  text-align: left;
}

.my-side-header-block {
  display: block;
  width: 10rem;
  background-color: #f5f5f5;
  display: flex;
  align-items: flex-end;
}

@media (max-width: 1220px) {
  .my-side-header-block_text {
    width: 6rem;
  }
}

@media (max-width: 1024px) {
  .my-side-header-block {
    display: none;
  }
}

.my-side-header-block_text {
  transform: rotate(270deg);
  transform-origin: left top;
  margin: -8rem 0;
  font-size: 80px;
  text-transform: uppercase;
  display: inline-block;
  white-space: nowrap;
  z-index: 10;
  color: rgb(17 17 17 / 15%);
  line-height: 10rem;
}

@media (max-width: 1220px) {
  .my-side-header-block_text {
    font-size: 65px;
    margin: -8rem 0;
    line-height: 9rem;
  }
}

.my-carousel-img_modal-link {
  position: absolute;
  left: calc(50% - 4.5rem);
  top: calc(50% - 4.5rem);
  appearance: none;
  width: 9rem;
  height: 9rem;
  border: none;
  outline: none;
  box-shadow: none;
  z-index: 1;
  border-radius: 50%;
  background-color: #447be0;
  background-image: url(../assets/landing-img/zoom.png);
  background-size: 2.625rem;
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0;
  transform: scale(0.5);
  transition: all 0.7s;
}

.my-modal-arrow {
  width: 2rem;
  height: 2rem;
  mask-repeat: no-repeat;
  mask-position: center;
  background-color: transparent;
  position: fixed;
  top: 50vh;
  cursor: pointer;
}

.my-modal-arrow.active {
  background-color: white;
}

.my-modal-arrow_back {
  --svg-back: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 461 532'%3E%3Cpath d='M460 531 1 266 460 1v530Z'/%3E%3C/svg%3E%0A");
  -webkit-mask-image: var(--svg-back);
  mask-image: var(--svg-back);
  left: 2rem;
}

.my-modal-arrow_forward {
  --svg-forvard: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 461 532'%3E%3Cpath d='m1 1 459 265L1 531V1Z'/%3E%3C/svg%3E%0A");
  -webkit-mask-image: var(--svg-forvard);
  mask-image: var(--svg-forvard);
  right: 2rem;
}

.my-w100 {
  width: 100%;
}
</style>